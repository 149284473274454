<template>
  <div class="ststem-log">
    <div class="ststem-log-filter-bar">
      <el-select class="ststem-log-filter-bar-user-agent" v-model="userAgent" placeholder="全部" clearable
        :popper-append-to-body="false">
        <el-option v-for="item in ['web', 'app']" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
      <el-date-picker class="ststem-log-filter-bar-date-range-picker" v-model="selectedDates" type="daterange"
        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00','23:59:59']"
        clearable :append-to-body="false">
      </el-date-picker>
    </div>
    <div class="ststem-log-table">
      <el-table :data="dataArray" border tooltip-effect="light myTooltips">
        <el-table-column prop="operator" label="操作人" min-width="150/1318%">
        </el-table-column>
        <el-table-column prop="content" label="内容" min-width="452/1318%">
        </el-table-column>
        <el-table-column prop="hospitalName" label="机构" min-width="300/1318%">
        </el-table-column>
        <el-table-column prop="createTime" label="时间" min-width="236/1318%">
        </el-table-column>
        <el-table-column prop="userAgent" label="来源" min-width="180/1318%">
        </el-table-column>
      </el-table>
    </div>
    <div class="ststem-log-pagination">
      <pagination v-if="totalPage != 0" class="device-list-pagination" :total="total" :current-page="currentPage"
        @pagechange="onPageChange"></pagination>
    </div>
  </div>
</template>

<script>
import { Select, Option, DatePicker, Table, TableColumn } from "element-ui";
import Pagination from "@c/common/paging-list/pagination.vue";
import { DateTool } from "@js/date-tool.js";
export default {
  components: {
    elSelect: Select,
    elOption: Option,
    elDatePicker: DatePicker,
    elTable: Table,
    elTableColumn: TableColumn,
    Pagination
  },

  data() {
    return {
      dataArray: [],
      //filter
      selectedDates: undefined,
      userAgent: undefined,
      //pagination
      currentPage: undefined,
      totalPage: undefined,
      total: undefined,
    }
  },

  computed: {
    hospitalId() {
      return this.$store.state.organizationModel?.id;
    }
  },

  mounted() {
    this.onSelectedOneMonth() //初始筛选1个月
  },

  watch: {
    hospitalId() {
      this.currentPage = 1
      this.getLogList()
    },

    userAgent() {
      this.currentPage = 1
      this.getLogList()
    },

    selectedDates() {
      this.currentPage = 1
      this.getLogList()
    }
  },

  methods: {
    onSelectedOneMonth() {
      //startDate
      var startDate = new Date()
      startDate.setDate(startDate.getDate() - 30)
      startDate.setHours(0)
      startDate.setMinutes(0)
      startDate.setSeconds(0)
      //endDate
      let endDate = new Date()
      endDate.setHours(23)
      endDate.setMinutes(59)
      endDate.setSeconds(59)
      this.selectedDates = [startDate, endDate]
    },

    onPageChange(newPage) {
      this.currentPage = newPage
      this.getLogList()
    },

    async getLogList() {
      try {
        const startDate = this.selectedDates ? this.selectedDates[0] : null
        const endDate = this.selectedDates ? this.selectedDates[1] : null
        const params = {
          "pageNum": this.currentPage,
          "project": "nppv",
          "category": "操作日志",
          "pageSize": 10,
          "hospitalIds": this.hospitalId ? [this.hospitalId] : null,
          "userAgent": this.userAgent,
          "startTime": DateTool.dateToStr(startDate),
          "endTime": DateTool.dateToStr(endDate)
        }
        const respone = await this.$api.getOperationLogList(params)
        this.dataArray = this.composeDataArray(respone.list)
        this.currentPage = respone.pageNum
        this.totalPage = respone.pages
        this.total = respone.total
      } catch (error) {
        this.$toast.showRed(error)
      }
    },

    composeDataArray(rawDataArray) {
      return rawDataArray.map((item) => {
        return {
          operator: placeholderText(item.operator),
          content: placeholderText(item.content),
          hospitalName: placeholderText(item.hospitalName),
          createTime: placeholderText(item.createTime),
          userAgent: placeholderText(item.userAgent),
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ststem-log {
  position: relative;
  height: 100vh;
  box-sizing: border-box;
  padding: 84px 40px;

  &-filter-bar {
    position: absolute;
    top: 0px;
    right: 40px;
    height: 84px;
    display: flex;
    align-items: center;

    &-user-agent {
      margin-right: 15px;
    }

    &-date-range-picker {
      margin-right: 280px;
    }

  }

  &-table {
    /deep/.el-table--border {
      border: 1px solid #E7E7E7 !important;
    }

    //header-cell
    /deep/.el-table th.el-table__cell>.cell {
      color: #909090;
      font-size: 14px;
      font-weight: normal;
      display: flex;
      align-items: center;
      margin-left: 14px;
    }


    //cell
    /deep/ .el-table td.el-table__cell div {
      color: rgba($color: #000000, $alpha: 0.9);
      font-size: 14px;
      margin-left: 14px;
    }
  }

  &-pagination {
    position: absolute;
    right: 40px;
    width: 100%;
    margin-top: 52px;
    display: flex;
    justify-content: flex-end;
  }



}
</style>
